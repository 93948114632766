import { useEffect, useRef } from "react";
import Image from "next/image";
import { Text } from "@/components/ui/Text";
import { cn } from "@/utils/tw";
export const MobileHeaderSlider = ({
  initialActiveKey,
  onChange
}: {
  initialActiveKey: string;
  onChange: (key: string) => void;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // scroll to the initial active key
    const el = ref.current?.querySelector("[data-id='" + initialActiveKey + "']");
    if (el) {
      el.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center"
      });
    }
  }, [initialActiveKey]);
  return <div className="container flex snap-x gap-4 overflow-y-hidden overscroll-y-none text-purple" ref={ref} data-sentry-component="MobileHeaderSlider" data-sentry-source-file="MobileHeaderSlider.tsx">
      <div className="relative mx-0 snap-center py-4" tabIndex={0} onClick={() => {
      onChange("/");
    }} data-id="/">
        <div className="absolute -bottom-4 right-2 h-48 w-28">
          <Image alt="" src="/img/landing-page/springboard.png" fill className="pointer-events-none object-contain" data-sentry-element="Image" data-sentry-source-file="MobileHeaderSlider.tsx" />
        </div>
        <div className={cn("h-36 w-52 rounded-3xl bg-beige-2 p-4", {
        "bg-yellow": initialActiveKey === "/"
      })}>
          <Text.P big className="max-w-2xl" data-sentry-element="unknown" data-sentry-source-file="MobileHeaderSlider.tsx">
            Vouchers
          </Text.P>
        </div>
      </div>

      <div className="relative mx-0 snap-center py-4" tabIndex={0} onClick={() => {
      onChange("/companies");
    }} data-id="/companies">
        <div className="absolute bottom-2 right-4 h-32 w-24">
          <Image alt="" src="/img/landing-page/fisher.png" className="pointer-events-none object-contain" fill data-sentry-element="Image" data-sentry-source-file="MobileHeaderSlider.tsx" />
        </div>
        <div className={cn("h-36 w-52 rounded-3xl bg-beige-2 p-4", {
        "bg-yellow": initialActiveKey === "/companies"
      })}>
          <Text.P big className="max-w-2xl" data-sentry-element="unknown" data-sentry-source-file="MobileHeaderSlider.tsx">
            Companies
          </Text.P>
        </div>
      </div>

      <div className="relative mx-0 snap-center py-4" tabIndex={0} onClick={() => {
      onChange("/candidates");
    }} data-id="/candidates">
        <div className="absolute -bottom-4 right-4 h-32 w-28">
          <Image alt="" src="/img/landing-page/skydiver.png" className="pointer-events-none object-contain" fill data-sentry-element="Image" data-sentry-source-file="MobileHeaderSlider.tsx" />
        </div>
        <div className={cn("h-36 w-52 rounded-3xl bg-beige-2 p-4", {
        "bg-yellow": initialActiveKey === "/candidates"
      })}>
          <Text.P big className="max-w-2xl" data-sentry-element="unknown" data-sentry-source-file="MobileHeaderSlider.tsx">
            Candidates
          </Text.P>
        </div>
      </div>
    </div>;
};