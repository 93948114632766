"use client";

import type { ButtonProps } from "@/components/ui/Button";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { Button } from "@/components/ui/Button";
import { useIsClient } from "@/hooks/useIsClient";
import { cn } from "@/utils/tw";
import { useLocaleProvider } from "../../LocaleProvider";
export const AllListingsButton = (props: ButtonProps) => {
  const {
    country,
    sourceContext
  } = useLocaleProvider();
  const isClient = useIsClient();
  const pathName = usePathname();
  if (!isClient) {
    return <div />;
  }
  if (!pathName?.includes("/discovery/") && !pathName?.includes("/listings/") && !sourceContext) {
    return <Link href="/discovery" className={cn({
      hidden: country !== "NO"
    })}>
        <Button variant="yellow" rounded {...props}>
          All Listings
        </Button>
      </Link>;
  }
  return <div data-sentry-component="AllListingsButton" data-sentry-source-file="AllListingsButton.tsx" />;
};