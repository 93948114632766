"use client";

import { useEffect, useState } from "react";
import { Button } from "@/components/ui/Button";
import { Text } from "@/components/ui/Text";
import { type PageContent } from "@/utils/i18n";
import { trpc } from "@/utils/TrpcProvider";
import { useSession } from "next-auth/react";
import { useLocaleProvider } from "../../app/[lang]/LocaleProvider";
import { SimpleDialog } from "../ui/dialog";
const t = {
  title: {
    en: "We noticed a change in your timezone",
    no: "Vi har lagt merke til en endring i tidssonen din",
    dk: "Vi har bemærket en ændring i din tidszone"
  },
  content: {
    en: (currTimezone: string, newTimezone: string) => `It seems like your timezone is set to ${newTimezone} instead of what is saved in your profile (${currTimezone}). Would you like to update your profile?`,
    no: (currTimezone: string, newTimezone: string) => `Det ser ut til at tidssonen din er satt til ${newTimezone} i stedet for det som er lagret i profilen din (${currTimezone}). Vil du oppdaterer din profil?`,
    dk: (currTimezone: string, newTimezone: string) => `Det ser ud til, at din tidszone er indstillet til ${newTimezone} i stedet for det, der er gemt i din profil (${currTimezone}). Vil du opdatere din profil?`
  },
  yes: {
    en: "Yes",
    no: "Ja",
    dk: "Ja"
  },
  no: {
    en: "No",
    no: "Nej",
    dk: "Nej"
  }
} satisfies PageContent;
const KEY = "HAS_BEEN_ASKED_FOR_TIMEZONE";
export function TimezoneSelectorForm() {
  const session = useSession();
  const user = session.data?.user;
  const {
    lang
  } = useLocaleProvider();

  // Set the value received from the local storage to a local state
  const [hasBeenAsked, setHasBeenAsked] = useState(true);
  const {
    timeZone
  } = Intl.DateTimeFormat().resolvedOptions();
  const updateTimezone = trpc.user.setTimezone.useMutation({
    onSuccess() {
      onClose();
    },
    onError: e => {
      console.error(e);
      onClose();
    }
  });
  useEffect(() => {
    const hasAskedInBrowser = localStorage.getItem(KEY);
    if (hasAskedInBrowser && hasAskedInBrowser === timeZone) {
      setHasBeenAsked(true);
      return;
    }
    if (user) {
      if (user.timezone) {
        if (!areTimezonesSameOffset(user.timezone, timeZone)) {
          setHasBeenAsked(false);
        }
      } else if (!areTimezonesSameOffset("Europe/Oslo", timeZone)) {
        setHasBeenAsked(false);
      }
    }
  }, [user, timeZone]);
  const onClose = () => {
    localStorage.setItem(KEY, timeZone);
    setHasBeenAsked(true);
  };
  if (!user) return null;
  if (!hasBeenAsked) return <SimpleDialog title={<Text.H5>{t.title[lang]}</Text.H5>} onClose={onClose} open wrapperClassName="md:max-w-2xl">
        <Text.P>{t.content[lang](user.timezone || "Europe/Oslo", timeZone)}</Text.P>
        <div className="flex gap-2">
          <Button onClick={onClose} variant="ghost" bordered>
            {t.no[lang]}
          </Button>
          <Button onClick={() => updateTimezone.mutate({
        timezone: timeZone
      })} variant="primary-dark">
            {t.yes[lang]}
          </Button>
        </div>
      </SimpleDialog>;
  return null;
}
function getOffsetMinutes(timeZone: string): number {
  // Get the current date
  const now = new Date();

  // Get the local time in the specified timezone
  const localTimeParts = new Intl.DateTimeFormat("en-US", {
    timeZone: timeZone,
    hour: "2-digit",
    minute: "2-digit",
    hour12: false
  }).formatToParts(now);

  // Extract hours and minutes
  const hours = parseInt(localTimeParts.find(part => part.type === "hour")?.value || "0", 10);
  const minutes = parseInt(localTimeParts.find(part => part.type === "minute")?.value || "0", 10);

  // Get the local time in UTC
  const utcTime = new Date(now.toLocaleString("en-US", {
    timeZone: "UTC"
  }));
  const utcHours = utcTime.getUTCHours();
  const utcMinutes = utcTime.getUTCMinutes();

  // Calculate the offset in minutes
  const offset = (hours - utcHours) * 60 + (minutes - utcMinutes);
  return offset;
}
function areTimezonesSameOffset(timeZone1: string, timeZone2: string): boolean {
  const offset1 = getOffsetMinutes(timeZone1);
  const offset2 = getOffsetMinutes(timeZone2);
  return offset1 === offset2;
}